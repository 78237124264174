import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/theme/layout"
import SEO from "../components/theme/seo"

import Masthead from "../components/strapi/structure/Masthead"
import Twocol from "../components/strapi/structure/TwoCol"
import Craftsmanship from "../components/strapi/structure/Craftsmanship"
import Outropiggy from "../components/strapi/structure/Outropiggy"

export default function HotTubs({ data }) {
  return (
    <Layout>
      <SEO title="Hot Tubs" />
      {data.allStrapiHottubLanding.edges.map(({ node }) => (
        <React.Fragment key={node}>
          <Masthead
            mhtitle={node.htub_masthead.mh_title}
            mhsubtitle={node.htub_masthead.mh_subtitle}
            mhbackground={node.htub_masthead.mh_bg.publicURL}
          />
          <Twocol
            title={node.htub_s300.txtimg_title}
            description={node.htub_s300.txtimg_description}
            blueTitle={node.htub_s300.txtimg_bluetitle}
            blueLink={node.htub_s300.txtimg_bluelink}
            order={node.htub_s300.txtimg_order}
            rowimg={node.htub_s300.txtimg_img.publicURL}
          />
          <Twocol
            title={node.htub_s500.txtimg_title}
            description={node.htub_s500.txtimg_description}
            blueTitle={node.htub_s500.txtimg_bluetitle}
            blueLink={node.htub_s500.txtimg_bluelink}
            bgColor={node.htub_s500.txtimg_bgcolor}
            order={node.htub_s500.txtimg_order}
            rowimg={node.htub_s500.txtimg_img.publicURL}
          />
          <Twocol
            title={node.htub_s700.txtimg_title}
            description={node.htub_s700.txtimg_description}
            blueTitle={node.htub_s700.txtimg_bluetitle}
            blueLink={node.htub_s700.txtimg_bluelink}
            bgColor={node.htub_s700.txtimg_bgcolor}
            order={node.htub_s700.txtimg_order}
            rowimg={node.htub_s700.txtimg_img.publicURL}
          />
          <Craftsmanship
            title={node.htub_craftsmanship.craft_title}
            description={node.htub_craftsmanship.craft_description}
          />
          <Outropiggy outro={node.htub_outro.piggy_outro} />
        </React.Fragment>
      ))}
    </Layout>
  )
}

export const query = graphql`
  query HotTubs {
    allStrapiHottubLanding {
      edges {
        node {
          id # id of the node
          htub_masthead {
            id
            mh_title
            mh_subtitle
            mh_bg {
              publicURL
            }
          }
          htub_s300 {
            id
            txtimg_title
            txtimg_description
            txtimg_bluelink
            txtimg_bluetitle
            txtimg_order
            txtimg_img {
              publicURL
            }
          }
          htub_s500 {
            id
            txtimg_title
            txtimg_description
            txtimg_bluelink
            txtimg_bluetitle
            txtimg_order
            txtimg_bgcolor
            txtimg_img {
              publicURL
            }
          }
          htub_s700 {
            id
            txtimg_title
            txtimg_description
            txtimg_bluelink
            txtimg_bluetitle
            txtimg_order
            txtimg_bgcolor
            txtimg_img {
              publicURL
            }
          }
          htub_craftsmanship {
            id
            craft_title
            craft_description
          }
          htub_outro {
            id
            piggy_outro
          }
        }
      }
    }
  }
`
